import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {FilterFormatter} from '../../common/filters/filter-formatter';
import {GeneralService} from 'ac-infra';
import {ServerInfoService} from '../../common/services/server-info.service';
import {AuthorizationService} from '../../common/services/authorization.service';

@Injectable({providedIn: 'root'})
export class NetworkFilterFormatterService extends FilterFormatter {

    private deviceMappedProps: Array<string>;
    private siteIdCallingApiList: Array<string>;
    private topologyTypes: any;

    constructor(private generalService: GeneralService) {
        super({
            setFilter: (resultFilterObject, filterObject, callingApi) => {
                this.setFilter(resultFilterObject, filterObject, callingApi);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject) => {
                this.addTimeAndDateFilter(filterObject, resultFilterObject);
            }
        });
        this.deviceMappedProps = [
            'devicesCallsStatistics',
            'devicesTopologyStatistics',
            'deviceFloatingLicense',
            'backupSummary',
            'backupSummaryFiles',
            'licensePool',
            'devices',
            'networkAlarmsSummary',
            'siteAlarmsSummary',
            'calls',
            'active',
            'history'
        ];

        this.topologyTypes = {
            endpointsMonitorStatistics: 'Endpoint',
            devicesMonitorStatistics: 'Device',
            sitesMonitorStatistics: 'Site',
            linksMonitorStatistics: 'Link'
        };

        this.siteIdCallingApiList = ['endpointsTopologyStatistics', 'endpointsMonitorStatistics', 'endpointsCallsStatistics', 'endpoints'];
    }

    public getTimeFilter = (filterObject) => {
        const resultFilterObject = {};
        filterObject = this.initFilterObject(filterObject);

        this.addTimeAndDateFilter(filterObject, resultFilterObject);

        return this.checkIfResultFilterIsEmptyObject(resultFilterObject);
    };

    setFilter = (resultFilterObject, filterObject, callingApi) => {
        if (!['devicesForDynamicTab', 'tenantDeviceBackup', 'tenantAllocations', 'tenantCallStorageTable', 'clusters', 'downtimes'].includes(callingApi)) {
            this.addTopologyOrServiceGroupIdsFilter(filterObject, resultFilterObject, callingApi);
            this.addGroupEndpointsIdsFilter(filterObject, resultFilterObject, callingApi);
        }

        if (['calls'].includes(callingApi)) {
        } else if (['active', 'history'].includes(callingApi)) {// agreed with Aliya - if user removes or not selecting any sourceType all sources types will be sent if alarms contains network filter
            if(_.isEmpty(resultFilterObject.unitType) && (filterObject.moreFilters_network || (filterObject.Status && Object.getOwnPropertyNames(filterObject).length > 0))){
                resultFilterObject.unitType = ['Device', 'Site', 'Link', 'Endpoint', 'Arm'];

                if(ServerInfoService.oneLiveMode){
                    resultFilterObject.unitType.push('Service');
                }
            }
        } else if(!ServerInfoService.oneLiveMode || (ServerInfoService.oneLiveMode && (AuthorizationService.isGlobalScope() || filterObject?.moreFilters_network?.devices?.length > 0))) {
            this.addTopologyFilter(filterObject, resultFilterObject, callingApi);
        }

        if(!callingApi.endsWith('MonitorStatistics') && !['topologyGroups', 'endpointGroups'].includes(callingApi)){
            const entitiesList = this.getLiveCloudEntityList(callingApi);
            this.addLiveCloudTopologyFilter(filterObject, resultFilterObject, callingApi, entitiesList, ServerInfoService.oneLiveMode && callingApi === 'calls', ['active', 'history'].includes(callingApi));
        }

        if (['tenantDeviceBackup', 'tenantAllocations', 'tenantCallStorageTable', 'networkTenants'].includes(callingApi)) {
            return;
        }

        if (callingApi === 'topologyGroups' || callingApi === 'endpointGroups' || callingApi === 'channel' || callingApi === 'networkTenants') {
            return;
        }

        if (!['calls', 'active', 'history', 'backupSummary', 'backupSummaryFiles'].includes(callingApi)) {
            this.addTimeAndDateFilter(filterObject, resultFilterObject, 'timestamp');

        }
        if (callingApi === 'devicesForDynamicTab') {
            return;

        }

        this.addStatusFilter(filterObject, resultFilterObject, callingApi);
        this.addCLMStatusFilter(filterObject, resultFilterObject, callingApi);
        this.addStatisticsTopologyTypeFilter(resultFilterObject, callingApi);
        this.addManagedFilter(filterObject, resultFilterObject, callingApi);
        this.addFamilyTypeFilter(filterObject, resultFilterObject, callingApi);
        this.addProductTypeFilter(filterObject, resultFilterObject, callingApi);
        this.addLinkTypeFilter(filterObject, resultFilterObject, callingApi);
        this.addServiceSourceFilter(filterObject, resultFilterObject, callingApi);
        this.addClustersFilter(filterObject, resultFilterObject, callingApi);
    };

    private addTopologyFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics')) {
            return;
        }

        let fieldName = 'id';
        if (this.siteIdCallingApiList.includes(callingApi) || callingApi === 'siteAlarmsSummary') {
            fieldName = 'siteId';
        } else if (callingApi === 'networkAlarmsSummary') {
            fieldName = 'unitId';
        }

        const topologyFilter = filterObject?.Topology;
        const devicesFilter = filterObject?.moreFilters_network?.devices;

        if ((topologyFilter || devicesFilter) && !['topologyGroups', 'endpointGroups', 'channel', 'tenantDeviceBackup', 'tenantAllocations', 'networkTenants', 'tenantCallStorageTable'].includes(callingApi)) {

            if(topologyFilter) {
                this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: topologyFilter.tenant});

                if (['channel', 'lcCustomers', 'services', 'siteLocations', 'servicesTopologyStatistics', 'clusters'].includes(callingApi)) {
                    return;
                }

                if (!['clusters', 'downtimes'].includes(callingApi)) {
                    this.assignToFieldIfArray({resultFilterObject, field: 'regionId', array: topologyFilter.region});// service has no region
                }
            }
            ['device', 'link', 'site'].forEach((entityType) => {
                if (['downtimes'].includes(callingApi) && entityType !== 'device') {
                    return;
                }

                const filter = (entityType === 'device' && devicesFilter) ? devicesFilter : topologyFilter?.[entityType];
                if ((topologyFilter?.[entityType] && topologyFilter?.[entityType].length > 0) || (entityType === 'device' && devicesFilter
                    && !['services', 'serviceGroups', 'servicesTopologyStatistics'].includes(callingApi))) {

                    if (['backupSummaryFiles', 'downtimes'].includes(callingApi) || (callingApi === 'serviceGroups' && entityType !== 'site')) {
                        fieldName = entityType + 'Id';
                    }

                    if(callingApi === 'entities'){
                        fieldName = 'entityId';
                    }

                    resultFilterObject[fieldName] = resultFilterObject[fieldName] || [];
                    resultFilterObject[fieldName].push.apply(resultFilterObject[fieldName], filter.map((entity) => entity?.id || entity));

                    if (entityType === 'site' && callingApi === 'siteAlarmsSummary') {// OVOC-2877
                        resultFilterObject.siteId = resultFilterObject.siteId || [];
                        resultFilterObject.siteId.push.apply(resultFilterObject.siteId, filter.map((entity) => entity?.id || entity));
                    }
                }
            });
        } else if (topologyFilter) {
            const field = callingApi === 'tenantAllocations' ? 'id' : 'tenantId';
            this.assignToFieldIfArray({resultFilterObject, field, array: topologyFilter.tenant});
        }
    };

    private getLiveCloudEntityList = (callingApi) => {
        let entitiesList = ['channel', 'lcCustomer', 'service', 'link'];

        if(callingApi.includes('channel')){
            entitiesList = ['channel'];
        } else if(callingApi.includes('lcCustomer')){
            entitiesList = ['channel', 'lcCustomer'];
        } else if(callingApi.includes('service')){
            entitiesList = ['channel', 'lcCustomer', 'service'];
        }

        return entitiesList;
    };

    private addStatusFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || ['backupSummary', 'backupSummaryFiles', 'deviceFloatingLicense'].includes(callingApi)) {
            return;
        }

        this.assignToServerFilter({resultFilterObject, restName: 'status', dataArray: filterObject.Status?.statuses});
    };

    private addCLMStatusFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi !== 'deviceFloatingLicense') {
            return;
        }

        if (filterObject.floatingLicense) {
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmStatus',
                dataArray: filterObject.floatingLicense.clmStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmDeviceStatus',
                dataArray: filterObject.floatingLicense.clmDeviceStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmReportStatus',
                dataArray: filterObject.floatingLicense.clmReportStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmConfigStatus',
                dataArray: filterObject.floatingLicense.clmConfigStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmOperationalState',
                dataArray: filterObject.floatingLicense.clmOperationalState
            });
        }
    };

    private addStatisticsTopologyTypeFilter = (resultFilterObject, callingApi) => {
        if (callingApi.endsWith('MonitorStatistics')) {
            resultFilterObject.topology = this.topologyTypes[callingApi.replace('SingleSelection', '')];
        }
    };


    // Network More Filters Section
    private addManagedFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || callingApi !== 'licensePool') {
            return;
        }

        const managedFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.managed;

        if (_.isString(managedFilter) && ['Managed', 'Not managed'].includes(managedFilter)) {
            resultFilterObject.managed = (managedFilter === 'Managed');
        }
    };

    private addChannelFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (!['serviceGroups', 'channel', 'servicesTopologyStatistics'].includes(callingApi)) {
            return;
        }

        const serverFieldName = callingApi === 'channel' ? 'name' : 'channelName';

        this.addStringFilterParameter(filterObject, 'channel', resultFilterObject, serverFieldName, 'moreFilters_network');
    };

    private addFamilyTypeFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || !this.deviceMappedProps.includes(callingApi)) {
            return;
        }

        const familyTypeFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.familyType;
        this.assignToServerFilter({resultFilterObject, restName: 'familyType', dataArray: familyTypeFilter});
    };

    private addGroupEndpointsIdsFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (['endpointGroups', 'endpoints', 'endpointsCallsStatistics', 'endpointsTopologyStatistics', 'networkAlarmsSummary', 'MonitorStatistics'].includes(callingApi)) {
            const groupIdsFilter = filterObject.Groups && filterObject.Groups.endpointsGroups;
            if (groupIdsFilter && groupIdsFilter.length > 0) {
                resultFilterObject[callingApi === 'endpointGroups' ? 'id' : 'endpointGroupId'] = groupIdsFilter;
            }
        }
    };

    private addProductTypeFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || !this.deviceMappedProps.includes(callingApi)) {
            return;
        }

        const productTypeFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.productType;
        this.assignToServerFilter({resultFilterObject, restName: 'productType', dataArray: productTypeFilter});
    };

    private addLinkTypeFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') ||
            !['calls', 'active', 'history', 'linksCallsStatistics', 'linksTopologyStatistics', 'links', 'networkAlarmsSummary'].includes(callingApi)) {
            return;
        }

        const linkTypeFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.linkType;
        this.assignToServerFilter({resultFilterObject, restName: 'categoryType', dataArray: linkTypeFilter});
    };

    private addServiceSourceFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (!['services', 'serviceGroups', 'servicesTopologyStatistics', 'networkAlarmsSummary', 'lcCustomers'].includes(callingApi)) {
            return;
        }

        const serviceSourceFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.serviceSource;
        this.assignToServerFilter({resultFilterObject, restName: 'serviceSource', dataArray: serviceSourceFilter});
    };


    private addClustersFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (!['clusters'].includes(callingApi)) {
            return;
        }

        const clustersFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.clusters;
        this.assignToServerFilter({resultFilterObject, restName: 'clusterId', dataArray: clustersFilter});
    };

    // Common
    private assignToServerFilter = ({resultFilterObject, restName, fieldName = undefined, dataArray = []}) => {
        if (_.isArray(dataArray) && dataArray.length > 0) {
            resultFilterObject[restName] = dataArray.map((value) => fieldName ? value[fieldName] : value.toString().toUpperCase());
        }
    };

}
